import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Invoicing",
  "description": "Invoice and invoicing plays a huge role in the process of foreign trade. Get familiar with the different types of invoices and how are each of them used. ",
  "author": "Ally Cheng",
  "categories": ["shipping"],
  "date": "2023-02-14T16:00:00.000Z",
  "featured": false,
  "tags": ["shipping"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Different types of invoices are used for different purposes in international trade. The names of these invoices indicate their specific type. The followings are the common types of invoices and their preparation methods:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Commercial Invoice`}</strong>{`: If the L/C regulations specify `}<em parentName="p">{`INVOICE, COMMERCIAL INVOICE, SHIPPING INVOICE,`}</em>{` or `}<em parentName="p">{`TRADE INVOICE`}</em>{`, the commercial invoice can be used by printing the name of the invoice with the words "INVOICE."`}</p>
        <p parentName="li">{`In NexPCB, the commercial invoice is made by the sales team before delivery and before the sales order is made.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Detailed Invoice`}</strong>{`: If the L/C requires "`}<em parentName="p">{`DETAILED INVOICE`}</em>{`," the word "`}<em parentName="p">{`DETAILED`}</em>{`" should be added in front of "`}<em parentName="p">{`INVOICE`}</em>{`." The content of the invoice should include details such as the name, specification, quantity, unit price, price conditions, and total value.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Verified Invoice`}</strong>{`: This type of invoice certifies the authenticity and correctness of its contents. The requirements for verification depending on the importer. If the L/C stipulates "`}<em parentName="p">{`CERTIFIED INVOICE`}</em>{`," the name of the invoice should be printed as such. The words "`}<em parentName="p">{`E. &. O.E.`}</em>{`" printed under the invoice should be crossed out, and "`}<em parentName="p">{`WE HEREBY CERTIFY THAT THE CONTENTS OF INVOICE HEREIN ARE TRUE & CORRECT`}</em>{`" should be indicated in the invoice.`}</p>
        <p parentName="li">{`Some countries have a specific format for confirming invoices, which can be used for a lower tariff or tax exemption for customs clearance of goods import. In some regions, importers can use actual invoices instead of customs invoices to handle customs clearance or obtain preferential tariffs. Some importers may exclude commissions from the price according to the actual invoice to claim extra-price remuneration.`}</p>
        <p parentName="li">{`If the L/C requires "`}<strong parentName="p">{`VISAED INVOICE`}</strong>{`" and specifies the visa person, the person must stamp and sign the invoice as a visa and add a statement of proof. If the visa person is not specified, the `}<em parentName="p">{`International Chamber of Commerce`}</em>{` is the visa holder, and the invoice is the same as the certified invoice.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Receipt Invoice`}</strong>{`, or `}<strong parentName="p">{`Payment Invoice`}</strong>{`: If the L/C requires "`}<em parentName="p">{`RECEIPT INVOICE,`}</em>{`" the name should be typed, and the payment has been received clause should be added at the signature of the invoice: "`}<em parentName="p">{`VALUE/PAYMENT RECEIVED UNDER CREDIT NO.×××ISSUED BY×××BANK.`}</em>{`" This type of invoice is often used under sight payment letter of credit to replace the payment receipt with a commercial invoice, without the need to issue a draft. Some importers also require such invoices to avoid stamp duty on bills of exchange.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Manufacturer's`}</strong>{` `}<strong parentName="p">{`Invoice`}</strong>{`: The manufacturer's invoice is a sales certificate issued by the manufacturer of the exported goods to express the ex-factory price in domestic currency. Its purpose is to be used by the customs of the importing country for valuation, tax verification, checking for price-cutting dumping, and collecting anti-dumping duties. If L/C requires "`}<em parentName="p">{`MANUFACTURERS INVOICE`}</em>{`," the name of the invoice should be typed as such.`}</p>
        <ol parentName="li">
          <li parentName="ol">{`The date of issue of the invoice should be earlier than the date of the commercial invoice.`}</li>
          <li parentName="ol">{`The price is the domestic market price expressed in the currency of the exporting country. The filling method is the same as that of the customs invoice, but the ex-factory price cannot be higher than the invoice price and should be appropriately discounted.`}</li>
          <li parentName="ol">{`The statement "`}<em parentName="li">{`WE HEREBY CERTIFY THAT WE ARE ACTUAL MANUFACTURER OF THE GOODS INVOICED`}</em>{`" should be added to the invoice to prove the manufacturer.`}</li>
          <li parentName="ol">{`The payee is the exporter, but the bill issuer is the manufacturer, and the person in charge of the factory should sign and seal the invoice.`}</li>
          <li parentName="ol">{`The manufacturer's invoice does not need to be marked unless it is stipulated in the letter of credit, the manufacturer's invoice does not need to be marked.`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Proforma invoice`}</strong>{`: An informal reference invoice issued by the seller at the buyer's request before the transaction, which cannot be used as a settlement document. If required by the L/C, the name should be typed, and the words "`}<em parentName="p">{`for the merchant to apply for a license`}</em>{`" or "`}<em parentName="p">{`this transaction is valid after the seller's final confirmation`}</em>{`" should be indicated.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Sample invoice`}</strong>{`: A list issued by the seller when sending samples to the buyer, for use in import declaration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Consular`}</strong>{` `}<strong parentName="p">{`invoice`}</strong>{`: An official invoice in a fixed format formulated by the consulate of the importing country and signed by the consulate, required in some Latin American countries to determine the origin of the goods, clarify differential treatment tariffs, or verify reasonable pricing or dumping issues.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Consignment`}</strong>{` `}<strong parentName="p">{`invoice`}</strong>{`: An invoice issued by the seller to the buyer as the basis for pricing when the goods are consigned.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Customs invoice`}</strong>{`: A specific invoice format required for import declarations by the customs authority of the importing country, also known as `}<strong parentName="p">{`COMBINED CERTIFICATE OF VALUE AND ORIGIN (C.C.V.O.).`}</strong>{` `}</p>
        <p parentName="li">{`In NexPCB, the customs invoice is made by the logistics team when the products are about to be delivered.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "the-importance-of-invoice"
    }}>{`The Importance of Invoice`}</h2>
    <ul>
      <li parentName="ul">{`The invoice is the legal proof of the transaction, the center of the shipping document, and provides a general description of the shipment.`}</li>
      <li parentName="ul">{`Invoices are the basis for buyers and sellers to receive and pay for goods, and to keep accounts.`}</li>
      <li parentName="ul">{`Invoices serve as the calculation basis for the buyer and the seller to handle customs declaration and pay taxes.`}</li>
      <li parentName="ul">{`When the L/C does not provide any draft, the invoice serves as the basis for payment instead of the draft.`}</li>
      <li parentName="ul">{`The invoice is the basis for the exporter to prepare other export documents.`}</li>
    </ul>
    <p>{`Overall, the main function of the invoice is for the `}<strong parentName="p">{`importer to receive goods, keep accounts, pay loans, and serve as the basis for customs declaration and tax payment.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      